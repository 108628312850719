<template>
  <b-modal
    :title="title"
    body-class="text-center"
    ref="modal"
    @hidden="onClear"
    ok-only
    ok-variant="secondary"
    ok-title="Закрыть"
    @shown="onOpenedModal"
  >
    <canvas ref="canvas"></canvas>
    <a class="btn btn-secondary" :download="fileNameJpg" :href="dataUrlJpg">Скачать JPG</a>
    <a class="btn btn-primary ml-2" :download="fileNameSvg" :href="dataUrlSvg">Скачать SVG</a>
  </b-modal>
</template>

<script>
import { BModal } from 'bootstrap-vue';
import QRCode from 'qrcode';

export default {
  components: {
    BModal,
  },
  data() {
    return {
      url: null,
      title: null,
      dataUrlJpg: null,
      dataUrlSvg: null,
    };
  },
  computed: {
    fileNameJpg() {
      return `QR ${this.title}.jpg`;
    },
    fileNameSvg() {
      return `QR ${this.title}.svg`;
    },
  },
  methods: {
    show(type, item) {
      const baseUrl = process.env.VUE_APP_SITE_URL || 'https://orel-app.ru/';
      this.url = `${baseUrl}?qr=1&t=${type}&id=${item.id}`;
      this.title = item.title;
      this.$refs.modal.show();
    },
    onClear() {
      this.title = null;
    },
    async onOpenedModal() {
      await QRCode.toCanvas(this.$refs.canvas, this.url, { scale: 12 });
      this.generateDataUrl();
    },
    async generateDataUrl() {
      // generate JPG
      this.dataUrlJpg = this.$refs.canvas.toDataURL();

      // generate SVG
      const svg = await QRCode.toString(this.url, { type: 'svg' });
      const blob = new Blob([svg], { type: 'image/svg+xml;charset=utf-8' });
      this.dataUrlSvg = URL.createObjectURL(blob);
    },
  },
};
</script>
