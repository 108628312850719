<template>
  <div>
    <div class="d-flex justify-content-between">
      <h4>Посетить</h4>
      <b-button variant="outline-primary" @click="addClicked">Добавить</b-button>
    </div>
    <b-table
      class="mt-3"
      :fields="fields"
      :items="list"
      @row-clicked="(row) => rowClicked(row)"
      tbody-tr-class="pointer"
    >
      <template #cell(published)="{ value }">
        <b-checkbox :checked="value" @click.native.prevent.stop disabled />
      </template>
      <template #cell(show_on_homepage)="{ value }">
        <b-checkbox :checked="value" @click.native.prevent.stop disabled />
      </template>
      <template #cell(actions)="{ value, item }">
        <div class="d-flex justify-content-between align-items-center flex-nowrap">
          <div class="text-nowrap">
            <b-icon
              icon="share"
              variant="primary"
              class="mr-3"
              @click="onShowQrCode(item, $event)"
            />
            <b-icon
              icon="pencil"
              variant="primary"
              class="mr-3"
              @click="rowClicked(item, $event)"
            />
            <b-icon icon="trash-fill" variant="primary" @click="deleteClicked(item, $event)" />
          </div>
        </div>
      </template>
      <template #cell(description)="{ value }">
        <div class="restricted-height">{{ value }}</div>
      </template>
    </b-table>
    <div class="d-flex justify-content-center">
      <b-pagination class="m-auto" v-model="currentPage" :total-rows="total" per-page="25" />
    </div>
    <ModalQR ref="ModalQR"></ModalQR>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import ModalQR from '@/components/modals/ModalQR.vue';

import { Place } from '@/request';

export default {
  name: 'Places',
  components: {
    ModalQR,
  },
  computed: {
    ...mapState('placeTypes', ['placeTypes']),
  },
  data() {
    return {
      list: [],
      fields: [
        { key: 'title', label: 'Название' },
        { key: 'place_type_id', label: 'Тип', formatter: this.formatPlaceType },
        { key: 'address', label: 'Адрес' },
        { key: 'description', label: 'Описание' },
        { key: 'published', label: 'Опубликовано' },
        { key: 'show_on_homepage', label: 'На главной' },
        { key: 'actions', label: '' },
      ],
      currentPage: 1,
      total: 0,
    };
  },
  beforeMount() {
    this.ActionGetPlaceTypes();
  },
  mounted() {
    this.loadPlaces(this.currentPage);
  },
  methods: {
    ...mapActions('placeTypes', ['ActionGetPlaceTypes']),
    async loadPlaces(page) {
      try {
        const { data, total } = await Place.getPlaces({
          page,
          filters: { place_type: 'travel' },
        });
        this.list = data;
        this.total = total;
      } catch (e) {
        this.$bvToast.toast(e.message, { variant: 'danger' });
      }
    },
    addClicked() {
      this.$router.push({ name: 'NewPlace' });
    },
    rowClicked(row, event = undefined) {
      event?.stopPropagation();

      this.$router.push({ name: 'EditPlace', params: { id: row.id } });
    },
    async deleteClicked(row, event) {
      event?.stopPropagation();

      const userAgreed = await this.$bvModal.msgBoxConfirm(
        `Вы действительно хотите удалить место: ${row.title}?`,
        {
          okTitle: 'Удалить',
          cancelTitle: 'Отмена',
          cancelVariant: 'outline-secondary',
        },
      );

      if (!userAgreed) {
        return;
      }

      try {
        await Place.deletePlace(row.id);
        this.$bvToast.toast('Место удалено', { variant: 'success' });
        await this.loadPlaces();
      } catch (e) {
        this.$bvToast.toast(e.message, { variant: 'danger' });
      }
    },
    formatPlaceType(placeId) {
      return this.placeTypes.find(({ id }) => id === placeId)?.title;
    },
    onShowQrCode(item) {
      event?.stopPropagation();
      this.$refs.ModalQR.show('place', item);
    },
  },
  watch: {
    currentPage(newVal) {
      this.loadPlaces(newVal);
    },
  },
};
</script>

<style lang="scss" scoped>
.restricted-height {
  height: 100px;
  display: -webkit-box;
  -webkit-line-clamp: 4;
  -webkit-box-orient: vertical;
  overflow: hidden;
}
</style>
